<template>
  <v-card>
    <v-toolbar color="primary" dark>Angebot editieren</v-toolbar>
    <v-card-text>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="Data.Name"
                :rules="inputRules"
                label="Name"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="Data.Description"
                label="Beschreibung"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="Data.Website"
                label="Website"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn text @click="dialog.value = false">Schließen</v-btn>
      <v-btn tile color="primary" @click="Save(dialog)">Speichern</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      Data: {
        id: this.proposals.id,
        Name: this.proposals.name,
        Description: this.proposals.description,
        Website: this.proposals.website,
        UserId: this.proposals.userId,
      },
      inputRules: [(v) => !!v || "Pflichtfeld"],
    };
  },
  props: {
    proposal: null,
  },
  methods: {
    Reset() {
      this.Data.id = null;
      this.Data.Name = "";
      this.Data.Description = "";
      this.Data.Website = "";
      this.Data.UserId = null;
    },
    Close() {
      this.Reset();
      this.$root.$emit("close_edit_proposals");
    },
    Save() {
      let dto = {
        id: this.Data.id,
        Name: this.Data.Name,
        Description: this.Data.Description,
        Website: this.Data.Website,
        UserId: this.Data.UserId,
      };

      this.$store.dispatch("proposals/update", dto).then(() => {
        this.Close();
      });
    },
  },
};
</script>
