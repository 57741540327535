<template v-slot:default="dialog">
  <div>
  <v-container fluid>
    <v-form v-model="valid">
      <v-parallax
    height="300"
    src="https://images.unsplash.com/photo-1534246357846-40b500934c14?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
  ></v-parallax>
      <v-container style="margin-top: -12rem;">
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                <h2>Allgemeine Angebots-Informationen</h2>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="Data.Title"
                      :rules="inputRules"
                      label="Angebots Name *"
                      placeholder="z.B. Packrafting Einsteigerkurs"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="Data.PriceNetto"
                      :rules="inputRules"
                      label="Netto Preis *"
                      placeholder="z.B. 100,00"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="1">
                    <v-select
                      v-model="Data.Currency"
                      :items="Currencies"
                      label="Währung *"
                      item-text="title"
                      item-value="key"
                      required
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-select
                      v-model="Data.CategoryId"
                      :items="categories"
                      label="Kategorie *"
                      item-text="title"
                      item-value="id"
                      required
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="12">
            <v-card>
              <v-card-title>
                <h2>Bilder zum Angebot</h2>
              </v-card-title>
              <v-card-text>
                <v-file-input
                  v-model="Data.Images"
                  accept="image/*"
                  multiple
                  chips
                  label="Bilder"
                  prepend-icon="mdi-camera"
                  show-size
                  outlined
                  @change="onFileChange"
                ></v-file-input>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" v-for="image in Data.Images" :key="image.name">
            <v-img
              :src="image"
              max-width="100"
              max-height="100"
              contain
            ></v-img>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                <h2>Ort des Kurses / Angebots</h2>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-switch
                      v-model="Data.IsOnline"
                      :label="
                        'Handelt es sich um ein reines Online Angebot? ' + (Data.IsOnline ? 'Ja' : 'Nein')
                      "
                    ></v-switch>
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-select
                      v-model="Data.Country"
                      :items="countries"
                      label="Land"
                      item-text="text"
                      item-value="value"
                      :disabled="Data.IsOnline"
                    >
                    </v-select>
                    <v-text-field
                      v-model="Data.ZipCode"
                      label="Postleitzahl"
                      placeholder="z.B. 12345"
                      :disabled="Data.IsOnline"
                    ></v-text-field>
                    <v-text-field
                      v-model="Data.Street"
                      label="Straße"
                      placeholder="z.B. Musterstraße 1"
                      :disabled="Data.IsOnline"
                    ></v-text-field>
                    <v-text-field
                      v-model="Data.City"
                      label="Ort"
                      placeholder="z.B. Berlin"
                      :disabled="Data.IsOnline"
                    ></v-text-field>
                    <v-text-field
                      v-model="Data.MapsLink"
                      label="Teilen-Link zu Google Maps"
                      placeholder="z.B. https://goo.gl/maps/kmyhtFmpBb3YwpHJ8"
                      :disabled="Data.IsOnline"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                <h2>Online Terminbuchung (Calendly Integration)</h2>
              </v-card-title>
              <v-card-text>
                <v-row>
          <v-col>
            <p>
              Wenn Sie einen Online Terminbuchungsdienst nutzen, können Sie
              diesen hier einbinden. Die Teilnehmer können dann direkt über die
              Webseite einen Termin buchen. Dazu müssen Sie sich bei Calendly
              registrieren und einen Terminplaner erstellen. Die Url des
              Terminplaners können Sie dann hier eintragen.
            </p>
            <v-switch
              v-model="Data.UseCalendarly"
              label="Calendly Integration nutzen?"
            ></v-switch>
            <v-text-field
              v-model="Data.CalendarlyUrl"
              label="Calendly Url"
              placeholder="z.B. https://calendly.com/username/offername"
              :disabled="!Data.UseCalendarly"
            ></v-text-field>
          </v-col>
        </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                <h2>Angebotsbeschreibung / Informationen zum Kurs</h2>
              </v-card-title>
              <v-card-text>
                <v-row>
          <v-col cols="12" md="12">
            
            <v-tabs
              v-model="tab"
              color="deep-purple-accent-4"
              align-tabs="center"
            >
              <v-tab :value="0">Beschreibung *</v-tab>
              <v-tab :value="1">Konditionen *</v-tab>
              <v-tab :value="2">Kursinhalte *</v-tab>
            </v-tabs>
            <v-window v-model="tab">
              <v-window-item :value="0">
                <v-tiptap
                  v-model="Data.Description"
                  :uploadImage="uploadImage"
                  placeholder="Beschreiben Sie den Kurs. z.B. Was ist der Kurs, für wen ist der Kurs, etc."
                  height="600"
                >
                </v-tiptap>
              </v-window-item>
              <v-window-item :value="1">
                <v-tiptap
                  v-model="Data.Conditions"
                  :uploadImage="uploadImage"
                  placeholder="Beschreiben Sie die Konditionen z.B. Anzahl der Tage, Anzahl der Teilnehmer, etc."
                  height="600"
                >
                </v-tiptap>
              </v-window-item>
              <v-window-item :value="2">
                <v-tiptap
                  v-model="Data.Contents"
                  :uploadImage="uploadImage"
                  placeholder="Beschreiben Sie die Inhalte des Kurses. z.B. Was wird gelernt, was wird geboten, etc."
                  height="600"
                >
                </v-tiptap>
              </v-window-item>
            </v-window>
          </v-col>
        </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-btn color="primary" class="mb-2 mt-3"
                  block @click="Save()" :disabled="!valid"
        >Speichern</v-btn
      >
      </v-container>
    </v-form>
  </v-container>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      valid: false,
      tab: 0,
      Currency: "EUR",
      Currencies: [
        { key: "EUR", title: "EUR" },
        { key: "CHF", title: "CHF" },
      ],
      countries: [
        { text: "Deutschland", value: "DE" },
        { text: "Österreich", value: "AT" },
        { text: "Schweiz", value: "CH" },
      ],
      Data: {
        Title: "",
        Description: "",
        Contents: "",
        Conditions: "",
        PriceNetto: 0,
        Currency: "EUR",
        Images: [],
        IdentityId: this.UserId,
        CategoryId: 0,
        IsOnline: false,
        Country: "DE",
        ZipCode: "",
        Street: "",
        City: "",
        MapsLink: "",
        CalendarlyUrl: "",
        UseCalendarly: false,
      },
      inputRules: [(v) => !!v || "Pflichtfeld"],
    };
  },
  methods: {
    async uploadImage(file) {
      console.log(file);
      //const url = (await myApi.upload(file)).url;
      let url = "https://picsum.photos/200/300";
      return url;
    },
    async GetProposals() {
      await this.$store.dispatch("proposals/getAll");
    },
    Reset() {
      this.Data.Title = "";
      (this.Data.Description = ""), (this.Data.Contents = "");
      this.Data.Conditions = "";
      this.Data.Images = [];
      this.Data.IdentityId = this.UserId;
      this.Data.CategoryId = 0;
      this.Data.IsOnline = false;
      this.Data.Country = "DE";
      this.Data.ZipCode = "";
      this.Data.Street = "";
      this.Data.City = "";
      this.Data.MapsLink = "";
      this.Data.CalendarlyUrl = "";
      this.Data.UseCalendarly = false;
    },
    Save() {
      let dto = {
        Title: this.Data.Title,
        Description: this.Data.Description,
        Contents: this.Data.Contents,
        Conditions: this.Data.Conditions,
        PriceNetto: this.Data.PriceNetto,
        Currency: this.Data.Currency,
        //Images: this.Data.Images,
        //IdentityId: this.UserId(),
        CategoryId: this.Data.CategoryId,
        IsOnline: this.Data.IsOnline,
        Country: this.Data.Country,
        ZipCode: this.Data.ZipCode,
        Street: this.Data.Street,
        City: this.Data.City,
        MapsLink: this.Data.MapsLink,
        CalendarlyUrl: this.Data.CalendarlyUrl,
        UseCalendarly: this.Data.UseCalendarly,
      };

      this.$store.dispatch("proposals/create", dto).then(() => {
        this.$root.$emit("reload_proposals_data");
        this.Reset();
        this.$router.push({ name: "proposals" });
      });
    },
  },
  computed: {
    categories() {
      let data = this.$store.state.categories.all.items;
      console.info("categories", data);
      return data;
    },
    isLoading() {
      return this.$store.state.categories.all.loading;
    },
  },
};
</script>
  