<template>
    <div>
      <v-data-table dense
        :headers="headers"
        :items="proposals.items"
        item-key="id"
        :loading="isLoading"
        loading-text="Wird geladen"
        :search="search"
        no-data-text="keine Daten vorhanden"
        no-results-text="keine Suchergebnisse"
        locale="de-de"
        :items-per-page="25"
        class="elevation-1 color_table"
        :footer-props="{
          itemsPerPageAllText: 'Alle',
          itemsPerPageText: 'Einträge pro Seite',
        }"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Suchen"
            class="mx-4"
          ></v-text-field>
        </template>


        <template v-slot:item.createdAt="{ item }">
        <span>{{ parseDate(item.createdAt) }}</span>
      </template>
  
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="showConfirmBar(item)">
            mdi-delete
          </v-icon>
        </template>
  
        <template v-slot:no-data>
          <v-btn color="primary" tile @click="getData"> Neu laden </v-btn>
        </template>
      </v-data-table>
    
  
      <div class="text-center ma-2">
        <v-snackbar v-model="confirmBar" bottom :timeout="10000">
          Möchten Sie das Angebot wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.
          <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="closeConfirmBar()">
              Abbrechen
            </v-btn>
  
            <v-btn color="accent" text v-bind="attrs" @click="deleteItem()">
              Bestätigen
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </div>
  </template>
  
  <script>
import moment from "moment";

  export default {
      created() {
          this.getData();
      },
      mounted() {
          this.$root.$on("reload_proposals_data", () => {
              this.getData();
          });     
      },
      data() {
          return {
              confirmBar: false,
              selectedItem: null,
              editVisible: false,
              headers: [
                  { text: "Erstellt am", value: "createdAt" },
                  { text: "Name", value: "title" },
                  { text: "Kategorie", value: "category.title" },
                  { text: "Preis", value: "priceNetto" },
                  { text: "Aktionen", value: "actions", sortable: false },
              ],
              search: "",
          };
      },
      computed: {
          proposals() {
              return this.$store.state.proposals.all;
          },
          isLoading() {
              return this.$store.state.proposals.all.loading;
          },
      },
      methods: {
        parseDate(date) {
      return moment.utc(date).format("DD.MM.yyyy hh:mm");
    },
          async getData() {
              await this.$store.dispatch("proposals/getAll");
          },
          closeConfirmBar() {
              this.confirmBar = false;
              this.selectedItem = null;
          },
          showConfirmBar(item) {
              this.selectedItem = item;
              this.confirmBar = true;
          },
          editItem(item) {
            this.selectedItem = item;
            console.log(this.selectedItem);
            if (this.selectedItem == null) return;
            this.editVisible = true;
          },
          deleteItem() {
              if (this.selectedItem == null)
                  return;
              this.$store.dispatch("proposals/delete", this.selectedItem).then(() => {
                  this.$root.$emit("reload_proposals_data");
                  this.closeConfirmBar();
              });
          },
      },
  };
  </script>
  