import Vue from 'vue'
import VueRouter from 'vue-router'

import Dashboard from "../components/views/Dashboard"
import Proposals from "../components/views/Proposals/Proposals"
import EditProposal from "../components/views/Proposals/EditProposal"
import NewProposal from "../components/views/Proposals/NewProposal"
import Subscription from "../components/views/Subscription"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Dashboard,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '/subscription',
    component: Subscription,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '/proposals',
    component: Proposals,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '/proposals/new',
    name: 'NewProposal',
    component: NewProposal,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '/proposals/:id',
    component: EditProposal,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '*',
    redirect: '/',
    meta: {
      isAuthenticated: true
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.isAuthenticated) {
    // Get the actual url of the app, it's needed for Keycloak
    const basePath = window.location.toString()
    if (!Vue.$keycloak.authenticated) {
      // The page is protected and the user is not authenticated. Force a login.
      Vue.$keycloak.login({ redirectUri: basePath.slice(0, -1) + to.path })
    } else if (Vue.$keycloak.hasResourceRole(process.env.VUE_APP_KEYCLOAK_ROLE)) {
      // The user was authenticated, and has the app role
      Vue.$keycloak.updateToken(70)
        .then(() => {
          localStorage.setItem("vue-refresh-token",  Vue.$keycloak.refreshToken);
          next()
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      localStorage.setItem("vue-token",  Vue.$keycloak.token);
      // The user was authenticated, but did not have the correct role
      // Redirect to an error page
      next()
    }
  } else {
    localStorage.setItem("vue-token",  Vue.$keycloak.token);
    // This page did not require authentication
    next()
  }
})

export default router
