import Vue from 'vue'
import App from './App.vue'
//import * as Keycloak from 'keycloak-js'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import authentication from "./plugins/authentication";

//import * as Sentry from "@sentry/vue";
//import { BrowserTracing } from "@sentry/tracing";

import VTiptap from "@peepi/vuetify-tiptap";

Vue.directive('plaintext', {
  bind(el, binding, vnode) {
    console.log(binding);
    console.log(vnode);
    el.innerHTML = el.innerText;
    //el.innerHTML = el.innerHTML.replace(/<[^>]+>/gm, '');
  }
});

/*Sentry.init({
  Vue,
  dsn: "https://f53a45a0f2b64eeda9d14c2860823b0c@sentry.marcogriep.de/4",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", "packlight.german-outdoors.de", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});*/

Vue.use(authentication);
Vue.use(VTiptap);

import VueJwtDecode from 'vue-jwt-decode'

Vue.use(VueJwtDecode)
Vue.use(vuetify)

Vue.$keycloak
  .init({ checkLoginIframe: false })
  .then(() => {
    new Vue({
      vuetify,
      router,
      store,
      render: h => h(App),
    }).$mount('#app')

  })

