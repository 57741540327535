import { proposalsService } from '../services';

export const proposals = {
    namespaced: true,
    state: {
        all: {},
        selected: {},
        default: {},
    },
    actions: {
        getAll({ commit }, id) {
            commit('getAllRequest');

            return new Promise((resolve, reject) => {
                proposalsService.getAll(id)
                .then(
                    payload => {
                        commit('getAllSuccess', payload)
                        resolve(payload)
                    }, 
                    error => {
                        commit('getAllFailure', error)
                        reject(error)
                    } 
                )
            });
            
        },

    
        delete({
            commit
        }, dto) {
            commit('defaultRequest');

            return new Promise((resolve, reject) => {
                proposalsService.deleteExisting(dto)
                    .then(
                        payload => {
                            commit('defaultRequest', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('defaultRequest', error);
                            reject(error)
                        }
                    );
            })
        },

        update({
            commit
        }, dto) {
            commit('defaultRequest');

            return new Promise((resolve, reject) => {
                proposalsService.updateExisting(dto)
                    .then(
                        payload => {
                            commit('defaultRequest', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('defaultRequest', error);
                            reject(error)
                        }
                    );
            })
        },

        create({
            commit
        }, dto) {
            commit('defaultRequest');

            return new Promise((resolve, reject) => {
                proposalsService.create(dto)
                    .then(
                        payload => {
                            commit('defaultSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('defaultFailure', error);
                            reject(error)
                        }
                    );
            })
        },
    },
    mutations: {

        defaultRequest(state) {
            state.default = {
                loading: true
            }; 
        },
        defaultSuccess(state, payload) {
            state.default = {
                item: payload,
                loading: false
            }; 
        },
        defaultFailure(state, error) {
            state.default = {
                error,
                loading: false
            }; 
        },

        uploadedSuccess(state, payload) {
            let info = "";
            for (var pair of payload.entries()) {
                if (pair[0] == "ProfilePicture") {
                    info = pair[1].name;
                    break;
                }
              }
            state.uploaded.push(info);
        },
        uploadedFailed(state, error) {
            let info = "";
            for (var pair of error.entries()) {
                if (pair[0] == "ProfilePicture") {
                    info = pair[1].name;
                    break;
                }
              }

            state.failed.push(info);
        },

        getMyProfileRequest(state) {
            state.myself = { loading: true };
        },
        getMyProfileSuccess(state, user) {
            state.myself = { item: user };
        },
        getMyProfileFailure(state, error) {
            state.myself = { error };
        },


        getRequest(state) {
            state.selected = { loading: true };
        },
        getSuccess(state, user) {
            state.selected = { item: user };
        },
        getFailure(state, error) {
            state.selected = { error };
        },
        getAllRequest(state) {
            state.all = { loading: true };
        },
        getAllSuccess(state, users) {
            state.all = { items: users };
        },
        getAllFailure(state, error) {
            state.all = { error };
        }
    }
}