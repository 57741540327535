//import * as Sentry from "@sentry/vue";

import {
    authHeader,
} from '../helpers';

export const proposalsService = {
    getAll,
    create,
    updateExisting,
    deleteExisting,
};


const route = '/api/Proposals'

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route + '/GetMyProposals', requestOptions).then(handleResponse);
}

function create(payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    console.log(payload);

    return fetch(process.env.VUE_APP_API_ROUTE + route + "/PostProposal", requestOptions).then(handleResponse);
}

function updateExisting(payload) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    console.info(payload);

    return fetch(process.env.VUE_APP_API_ROUTE + route + '/UpdateExpert/' + payload.id, requestOptions).then(handleResponse);
}


function deleteExisting(payload) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route + '/' + payload.id, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('vue-token');
                location.reload(true);               
            }

            const error = (data && data.message) || response.statusText;
            //this.$store.dispatch("alert/error", error);
            //Sentry.captureException(error);
            return Promise.reject(error);
        }

        return data;
    });
}