<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <h3>Ihre Angebote</h3>
          <span
            >Hier können Sie Ihre Angebote zentral verwalten und haben die
            Möglichkeit, neue Angebote zu erstellen, bestehende Angebote zu
            bearbeiten oder zu löschen, um sicherzustellen, dass Ihr Angebot
            immer auf dem neuesten Stand ist und Ihren Kunden die bestmögliche
            Erfahrung bietet.
            
            Ein Angebot ist eine Einzelleistung, die Sie direkt mit einem Kunden
            vereinbaren. Ein Angebot könnte z.B. ein Survival Training sein.
            </span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ProposalTable></ProposalTable>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="expertsProfile.item == null">
          <p>Bevor Sie Angebote erstellen können. Müssen Sie erst ein Experten Profil anlegen.</p>
          <v-btn to="/" color="primary">Jetzt Experten Profil anlegen</v-btn>
        </v-col>
        <v-col v-else>
          <v-btn to="/proposals/new" color="primary">Neues Angebot erstellen</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
  
  <script>
import ProposalTable from "../../tables/Proposals/ProposalTable.vue";

export default {
  components: {
    ProposalTable,
  },
  computed: {
    expertsProfile() {
      let data = this.$store.state.experts.myself;
      console.info("expertsProfile", data);
      return data;
    },
  },
};
</script>
  